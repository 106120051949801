import $ from "jquery";
import imagesLoaded from "imagesloaded";
import Flickity from "flickity-bg-lazyload";
import jQueryBridget from "jquery-bridget";
import * as Helpers from "../helpers";

export default function() {
  console.log("-- Hero slider initialized");

  $.bridget("flickity", Flickity);

  var $heroSlider = $(".hero-slider-wrapper");
  var $heroCells = $heroSlider.find(".hero-slider-cells");
  var $firstCell = $heroCells.find(".cell").first();
  var $infoBar = $heroSlider.find(".info-bar");

  var setHeight = function() {
    var windowHeight = $(window).height();
    var headerHeight = Helpers.getVisibleHeight($("header.header"));
    var infoBarHeight = $(".info-bar").height();
    var pageHeadlineHeight = $(".page-headline-wrapper").height();
    var actionBarHeight =
      $(".action-bar-wrapper").length > 0
        ? $(".action-bar-wrapper").height()
        : 0;

    var targetHeight = windowHeight - pageHeadlineHeight - actionBarHeight;

    $heroSlider.css({
      height: `${targetHeight}px`
    });
  };

  if ($heroSlider.length > 0) {
    // For small devices, set the height of the slider
    if ($(window).width() <= 1023) {
      setHeight();

      $(window).on("resize", function() {
        setHeight();
      });
    }

    if ($heroCells.find(".cell").length > 1) {
      var $carousel = $heroCells.flickity({
        cellAlign: "left",
        contain: true,
        wrapAround: true,
        pageDots: false,
        cellSelector: ".cell",
        percentPosition: false,
        freeScroll: false,
        prevNextButtons: true,
        autoPlay: 4000,
        bgLazyLoad: 1,
        arrowShape: {
          x0: 30,
          x1: 50,
          y1: 20,
          x2: 60,
          y2: 20,
          x3: 40
        }
      });

      var $flickity = $carousel.data("flickity");

      if ($infoBar.length == 1) {
        $flickity.off("cellSelect"),
          $flickity.on("cellSelect", function() {
            var $cell = $(".hero-slider-cells .cell").eq(
              $flickity.selectedIndex
            );

            var content = $cell.data("content");

            if (content) {
              $infoBar.find("a").attr("href", content.url);
              $infoBar.find("a.booking").attr("href", content.bookingUrl);
              $infoBar.find(".sub a").html(content.category);
              $infoBar.find(".heading a").html(content.heading);
              $infoBar.find(".dates a").html(content.dates);
            }
          });
      }

      $(document).on("turbolinks:before-cache", function() {
        $carousel.flickity("destroy");

        $heroCells.removeClass("flickity-enabled").removeClass("is-draggable");
      });
    } else {
      $firstCell.css({
        backgroundImage: `url("${$firstCell.data("flickity-bg-lazyload")}")`
      });

      $firstCell.imagesLoaded({ background: true }, function() {
        $firstCell.addClass("flickity-bg-lazyloaded");
      });
    }
  }

  // -- Teardown before cache -- //
  $(document).on("turbolinks:before-cache", function() {
    $heroCells.find(".cell").removeClass("flickity-bg-lazyload");
  });
}
