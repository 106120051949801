import $ from "jquery";
import inview from "jquery-inview";
import imagesLoaded from "imagesloaded";

imagesLoaded.makeJQueryPlugin($);

export default function() {
  console.log("-- Grid initialized");

  $(".grid-item-wrapper").one("inview", function(event, isInView) {
    var $gridItem = $(this);

    if (isInView) {
      $gridItem.imagesLoaded({ background: true }, function() {
        $gridItem.addClass("show");
      });
    }
  });

  // -- Teardown before cache -- //
  $(document).on("turbolinks:before-cache", function() {
    $(".grid-item-wrapper").removeClass("show");
  });
}
