import $ from "jquery";
import Velocity from "velocity-animate";
import * as Helpers from "../helpers";
import * as Config from "../config";

export default function() {
  console.log("-- Share initialized");

  var $shareToggle = $("a.share-toggle");
  var $share = $(".share-overlay");

  var open = function() {
    Velocity($share, Config.overlayOpenProperties, {
      display: "block",
      duration: Config.duraton,
      easing: Config.easing
    });

    $share.addClass("active");
  };

  var close = function() {
    Velocity($share, Config.overlayCloseProperties, {
      display: "none",
      duration: Config.duraton,
      easing: Config.easing
    });

    $share.removeClass("active");
  };

  $shareToggle.off("click"),
    $shareToggle.on("click", function() {
      if ($share.hasClass("active")) {
        close();
      } else {
        open();
      }

      return false;
    });

  $(document).on("keyup", function(e) {
    if (e.keyCode == 27 && $share.hasClass("active")) close();
  });
}
