import $ from "jquery";
import imagesLoaded from "imagesloaded";
import InfiniteScroll from "infinite-scroll";

imagesLoaded.makeJQueryPlugin($);

export default function() {
  console.log("-- Posts initialized");

  var $posts = $(".post-wrapper");

  // Init infinite scroll
  var $postsWrapper = $(".posts-wrapper");

  if ($postsWrapper.length > 0 && $(".pagination a.next-page").length > 0) {
    var infScroll = new InfiniteScroll(".posts-wrapper", {
      path: ".pagination a.next-page",
      append: ".post-wrapper",
      hideNav: ".pagination",
      debug: false
    });

    // Show the spinner on request, Hide the spinner on loaded or last
    var $spinner = $(".infinite-scroll-load");

    infScroll.off("request load last"),
      infScroll
        .on("request", function() {
          $spinner.show();
        })
        .on("load", function() {
          $spinner.hide();
        })
        .on("last", function() {
          $spinner.hide();
        });
  }
}
