import $ from "jquery";
import inview from "jquery-inview";

export default function() {
  console.log("-- Action bar initialized");

  var $actionBar = $(".action-bar-wrapper");
  var $buffers = $(".you-may-also-like, footer.footer");
  var $buffer = $buffers.first(); // stick to the first whatever has been selected in $buffers

  $buffer.off("inview"),
    $buffer.on("inview", function(event, isInView) {
      if (isInView) {
        $actionBar.removeClass("fixed");
      } else {
        $actionBar.addClass("fixed");
      }
    });

  $(window).trigger("checkInView");
}
