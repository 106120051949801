import $ from 'jquery';
import Velocity from 'velocity-animate';
import * as Helpers from '../helpers';
import * as Config from '../config';

export default function() {
  console.log('-- Spektrix initialized');

  var $spektrixToggle = $('a.spektrix-toggle');
  var $spektrix = $('.spektrix-overlay');

  var open = function() {
    Velocity($spektrix, Config.overlayOpenProperties, {
      display: 'block',
      duration: Config.duraton,
      easing: Config.easing,
    });

    $('body').addClass('noscroll');

    $spektrix.addClass('active');
  };

  var close = function() {
    Velocity($spektrix, Config.overlayCloseProperties, {
      display: 'none',
      duration: Config.duraton,
      easing: Config.easing,
    });

    $('body').removeClass('noscroll');

    $spektrix.removeClass('active');
  };

  $spektrixToggle.on('click', function() {
    if ($spektrix.hasClass('active')) {
      close();
    } else {
      open();
    }

    return false;
  });

  $(document).on('keyup', function(e) {
    if (e.keyCode == 27 && $spektrix.hasClass('active')) close();
  });
}
