import $ from "jquery";
import Velocity from "velocity-animate";
import * as Helpers from "../helpers";
import * as Config from "../config";

export default function() {
  console.log("-- Calendar initialized");

  var $calendarToggle = $("a.calendar-toggle");
  var $calendar = $(".calendar-wrapper");

  // -------------------------- show and hide the calendar itself -------------------------- //

  var hideCalendar = function(direction = "down") {
    if (direction == "down") {
      var translateYParams = [20, 0];
    } else if (direction == "up") {
      var translateYParams = [-20, 0];
    }

    // Hide events first
    hideEvents();

    Velocity(
      $calendar,
      {
        opacity: 0,
        translateY: translateYParams
      },
      {
        display: "none",
        duration: Config.duration,
        easing: Config.easing
      }
    );

    $calendar.removeClass("active");

    $calendarToggle.removeClass("active").html("Show Calendar");
  };

  var showCalendar = function() {
    // Get the visible height of the header

    $calendar.css({
      top: Helpers.getVisibleHeight($("header.header")) + "px"
    });

    Velocity(
      $calendar,
      {
        opacity: 1,
        translateY: [0, 20]
      },
      {
        display: "block",
        duration: Config.duraton,
        easing: Config.easing,
        complete: () => {
          $calendar.focus();
        }
      }
    );

    $calendar.addClass("active");

    $calendarToggle.addClass("active").html("Hide Calendar");
  };

  // If calendar toggle is clicked
  $calendarToggle.off("click"),
    $calendarToggle.on("click", function() {
      if ($calendar.hasClass("active")) {
        hideCalendar();
      } else {
        showCalendar();
      }

      return false;
    });

  // Hide the calendar if the header is being hidden
  $(window).on("hide-calendar", function() {
    if ($calendar.hasClass("active")) {
      hideCalendar("up");
    }
  });

  // If esc pressed
  $(document).on("keyup", function(evt) {
    console.log(evt);
    if (evt.keyCode == 27) {
      if ($calendar.hasClass("active")) {
        hideCalendar();
      }
    }
  });

  // --------------------------  fetch and show events on click  -------------------------- //

  var $activeDays = $calendar.find("a.day.active");
  var $calendarEvents = $calendar.find(".calendar-events");

  var loadEvents = function(url) {
    $calendarEvents.load(url, "", function() {});
  };

  var showEvents = function() {
    // YYYY-MM-DD

    Velocity(
      $calendarEvents,
      {
        opacity: 1,
        translateY: [0, -20]
      },
      {
        display: "block",
        duration: Config.duraton,
        easing: Config.easing,
        complete: () => {
          $calendarEvents.focus();
        }
      }
    );

    $calendarEvents.addClass("active");
  };

  var hideEvents = function() {
    Velocity(
      $calendarEvents,
      {
        opacity: 0,
        translateY: [-20, 0]
      },
      {
        display: "none",
        duration: Config.duraton,
        easing: Config.easing
      }
    );

    $calendarEvents.removeClass("active");
  };

  $activeDays.off("click"),
    $activeDays.on("click", function(ev) {
      ev.preventDefault();

      var $day = $(this);
      var url = $day.attr("href");

      // If a selected day is clicked again, hide the events panel
      if ($calendarEvents.hasClass("active") && $day.hasClass("selected")) {
        hideEvents();

        $day.removeClass("selected");

        return;
      }

      // Remove selected class from all days
      $activeDays.removeClass("selected");

      // Set the selected class on this one
      $day.addClass("selected");

      // Load the events
      loadEvents(url);

      // If the events panel isn't shown yet, show it
      if (!$calendarEvents.hasClass("active")) {
        showEvents();
      }
    });
}
