import $ from "jquery";
import Velocity from "velocity-animate";
import * as Helpers from "../helpers";
import * as Config from "../config";

export default function() {
  console.log("-- Search initialized");

  var $searchToggle = $("a.search-toggle");
  var $search = $(".search-overlay");

  $search.find("input.q").focus();

  var open = function() {
    Velocity($search, Config.overlayOpenProperties, {
      display: "block",
      duration: Config.duraton,
      easing: Config.easing,
      complete: () => {
        $search.find("input.q").focus();
      }
    });

    $search.addClass("active");
  };

  var close = function() {
    Velocity($search, Config.overlayCloseProperties, {
      display: "none",
      duration: Config.duraton,
      easing: Config.easing
    });

    $search.removeClass("active");
  };

  $searchToggle.off("click"),
    $searchToggle.on("click", function() {
      if ($search.hasClass("active")) {
        close();
      } else {
        open();
      }

      return false;
    });

  $(document).on("keyup", function(e) {
    if (e.keyCode == 27 && $search.hasClass("active")) close();
  });
}
