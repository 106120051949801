import $ from "jquery";
import imagesLoaded from "imagesloaded";
import Packery from "packery";
import "jquery-bridget";
import * as Config from "../config";
import * as Helpers from "../helpers";

export default function() {
  console.log("-- Page blocks initialized");

  $.bridget("packery", Packery);

  var $pageBlocks = $(".page-blocks .page-block");

  var currentTallest = 0;
  var currentRowStart = 0;
  var rowDivs = new Array();
  var currentDiv;

  function setConformingHeight(el, newHeight) {
    // set the height to something new, but remember the original height in case things change
    el.data(
      "originalHeight",
      el.data("originalHeight") == undefined
        ? el.height()
        : el.data("originalHeight")
    );
    el.height(newHeight);
  }

  function getOriginalHeight(el) {
    // if the height has changed, send the originalHeight
    return el.data("originalHeight") == undefined
      ? el.height()
      : el.data("originalHeight");
  }

  function setRowHeights(rowDivs) {
    var shouldSetHeight = true;

    for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
      shouldSetHeight =
        ($(rowDivs[currentDiv]).hasClass("text") ||
          $(rowDivs[currentDiv]).hasClass("quote")) &&
        shouldSetHeight
          ? true
          : false;
    }

    if (shouldSetHeight) {
      for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
        setConformingHeight(rowDivs[currentDiv], currentTallest);
      }
    }
  }

  function columnConform() {
    if (Helpers.isLargeScreen()) {
      $pageBlocks.each(function(index) {
        if (currentRowStart != $(this).position().top) {
          // we just came to a new row.  Set all the heights on the completed row if they are all text or quotes
          setRowHeights(rowDivs);

          // set the variables for the new row
          rowDivs.length = 0; // empty the array
          currentRowStart = $(this).position().top;
          currentTallest = getOriginalHeight($(this));
          rowDivs.push($(this));
        } else {
          // another div on the current row.  Add it to the list and check if it's taller
          rowDivs.push($(this));
          currentTallest =
            currentTallest < getOriginalHeight($(this))
              ? getOriginalHeight($(this))
              : currentTallest;
        }

        // do the last row
        setRowHeights(rowDivs);
      });
    }
  }

  if ($pageBlocks.length > 1) {
    columnConform();
  }

  $(window).on("resize", function() {
    if ($pageBlocks.length > 1) {
      columnConform();
    }
  });

  if (Helpers.isLargeScreen() && $pageBlocks.length > 1) {
    // Need to implement packery when the font is loaded
    var checkFonts = setInterval(function() {
      if ($("html").hasClass("wf-active")) {
        $pageBlocks
          .find(".page-block")
          .imagesLoaded({ background: true }, function() {
            $(".page-blocks").packery({
              itemSelector: ".page-block",
              gutter: 0
            });
          });

        clearInterval(checkFonts);
      }
    }, 150);
  }
}
