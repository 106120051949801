import $ from "jquery";
import Velocity from "velocity-animate";
import * as Config from "../config";
import * as Helpers from "../helpers";

export default function() {
  console.log("-- Scroll anchor initialized");

  var $scrollAnchors = $("a.scroll-anchor");

  $scrollAnchors.off("click"),
    $scrollAnchors.on("click", function() {
      var target = $(this).data("target");

      if (target) {
        Velocity($(target), "scroll", {
          duration: Config.duration * 2,
          easing: Config.easing,
          offset: Helpers.getVisibleHeight($("header.header")) * -1
        });
      }

      return false;
    });
}
