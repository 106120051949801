// --------------------------  vendor  -------------------------- //

import WebFont from 'webfontloader';
import $ from 'jquery';
import Turbolinks from 'turbolinks';
import imagesLoaded from 'imagesloaded';
import Lazysizes from 'lazysizes';

import './lib/modernizr-custom';

// --------------------------  custom modules  -------------------------- //
import actionBar from './modules/actionBar';
import calendar from './modules/calendar';
import externalLinks from './modules/externalLinks';
import grid from './modules/grid';
import header from './modules/header';
import heroSlider from './modules/heroSlider';
import nav from './modules/nav';
import pageBlocks from './modules/pageBlocks';
import posts from './modules/posts';
import scrollAnchor from './modules/scrollAnchor';
import search from './modules/search';
import share from './modules/share';
import spektrix from './modules/spektrix';

import * as Config from './config';

imagesLoaded.makeJQueryPlugin($);

var Acca = {
  go: function() {
    actionBar();
    calendar();
    externalLinks();
    grid();
    header();
    heroSlider();
    nav();
    pageBlocks();
    posts();
    scrollAnchor();
    search();
    share();
    spektrix();
  },
};

// --------------------------  attach jquery to window  -------------------------- //

window.jQuery = $;

// --------------------------  init typekit  -------------------------- //

WebFont.load({
  typekit: {
    id: 'ymv0wbs',
  },
});

$(document).ready(function() {
  var $body = $('body');
  var $nav = $('nav.nav');

  // Kick everything off
  Acca.go();

  // --------------------------  initialise turbolinks  -------------------------- //

  Turbolinks.start();

  $(document).on('turbolinks:request-start', function() {
    console.log('-- Turbolinks fetching');
  });

  $(document).on('turbolinks:load', function() {
    console.log('-- Turbolinks load');

    // Get ride of any click events
    $('*').off('click');

    Acca.go();
  });

  // -- Teardown before cache -- //
  $(document).on('turbolinks:before-cache', function() {
    $('html').removeClass('js');
  });
});
