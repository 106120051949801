import $ from "jquery";
import Headroom from "headroom.js";

import * as Config from "../config";

export default function() {
  console.log("-- Header initialized");

  var $body = $("body");
  var $header = $("header.header");
  var $secondaryNav = $("nav.secondary");

  // --------------------------  show header on load  -------------------------- //

  $header.imagesLoaded({ background: true }, function() {
    $header.addClass("show");
  });

  // --------------------------  init headroom  -------------------------- //

  var headroom = new Headroom($header[0], {
    classes: Config.visibilityClasses,
    onPin: function() {
      $secondaryNav.addClass("pinned").removeClass("unpinned");
    },
    onUnpin: function() {
      $secondaryNav.addClass("unpinned").removeClass("pinned");
    }
  });

  headroom.init();

  // -- Teardown before cache -- //
  $(document).on("turbolinks:before-cache", function() {
    headroom.destroy();
  });
}
