import $ from "jquery";

export default function() {
  console.log("-- Nav initialized");

  var $navigationToggle = $("button.navigation-toggle");
  var $header = $("header.header");

  var closeHeader = function() {
    $header.toggleClass("active");
    $(window).trigger("hide-calendar");
  };

  $navigationToggle.off("click"),
    $navigationToggle.on("click", function() {
      closeHeader();
      return false;
    });

  var $primaryNavigationLinks = $header.find("nav.primary li a");

  $primaryNavigationLinks.off("click"),
    $primaryNavigationLinks.on("click", function() {
      closeHeader();
    });
}
