export const duration = 420;

export const easing = "easeInOutQuad";

export const overlayOpenProperties = {
  opacity: 1
};

export const overlayCloseProperties = {
  opacity: 0
};

export const visibilityClasses = {
  initial: "visibility",
  pinned: "visibility-pinned",
  unpinned: "visibility-unpinned",
  top: "visibility-top",
  notTop: "visibility-not-top",
  bottom: "visibility-bottom",
  notBottom: "visibility-not-bottom"
};
